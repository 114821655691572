




































import Vue from 'vue';
import { TeamMember } from '@/models/teamMember.model';

export default Vue.extend({
  name: 'TeamMemberCard',
  props: {
    teamMember: Object as () => TeamMember,
    teamMemberImageUrl: String
  },
  data() {
    return {
      annualySalaryContracts: ['Full-time', 'Part-time']
    };
  }
});
